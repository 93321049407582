.custom-dd {
    width: 80px;
    border: 1px solid #556EE6 ;
    border-radius: 4px;
    background-color: #f5f5f5;
    color: #000000;
  }

  .sticky-th {
    position: sticky;
    top: 0;
    background-color: #F6F6FA;
  }