


.stepper {
  position: relative;
}

.stepper .col.stroke {
  height: 2px;
  background-color: #d3d2d2;
  position: absolute;
  top: 25px;
  z-index: -1;
  width: 92%;
  left: 70px;
}

@media (max-width: 1346px) {
  .stepper .col.stroke {
    width: 85%;
  }
}

// @media (max-width: 1492) {
//   .stepper .col.stroke {
//     width: 77%;
//   }
// }


.textarea-container {
  position: relative;
}

.character-counter {
  position: absolute;
  bottom: 8px;
  right: 8px;
  margin: 0;
  font-size: 12px;
  color: gray;
}

// .modal-history-popscreen{
//   width: 75vw;
//   max-width: none;
//   height: 100%;
//   margin: 1;
//   z-index:1000;
// }

.des-char{
  position:absolute;
  bottom: -18px;
  right: 13px;
}