
//
// apexcharts.scss
//
.apex-charts {
    min-height: 10px !important;
    text {
        font-family: $font-family-base !important;
        fill: $gray-500;
    }
    .apexcharts-canvas {
        margin: 0 auto;
    }
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
    font-family: $font-family-base !important;
}

.apexcharts-legend-series {
    font-weight: $fw-medium;
}

.apexcharts-gridline {
    pointer-events: none;
    stroke: $apex-grid-color;
}

.apexcharts-legend-text {
    color: $gray-600 !important;
    font-family: $font-family-base !important;
    font-size: 12px !important;
    margin-left: 0px !important; 
    line-height: 12px;
}

.apexcharts-pie-label {
    fill: $white !important;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
    text {
        font-family: $font-family-base !important;
        fill: #6A7187;
    }
}

[data-layout-mode="dark"] {
    .apexcharts-gridline {
        stroke: var(--#{$prefix}gray-300);
    }

    .apexcharts-radialbar-track.apexcharts-track {
        path {
            stroke: var(--#{$prefix}gray-300);
        }
    }

    .apexcharts-tooltip {
        background-color: var(--#{$prefix}custom-white) !important;
        border: 1px solid var(--#{$prefix}border-color) !important;

        .apexcharts-tooltip-title {
            background-color: var(--#{$prefix}gray-300) !important;
            border-bottom: 1px solid var(--#{$prefix}border-color) !important;
        }
    }

    .apexcharts-pie-area {
        stroke: var(--#{$prefix}gray-300);
    }

    .apexcharts-pie-label {
        fill: var(--#{$prefix}gray-600) !important;
    }
    }

    .apexcharts-legend-marker {
        border-radius: 2px !important;
        margin: 2px 0 2px 0 !important;

    }
    .pie-chart-any{
        margin-bottom: 62px !important;
        height: 100% !important;
        width: 100% !important;
        .apexcharts-legend{
            height : 100% !important;
            top: 0 !important;
            overflow: hidden !important;
        }
    }
    .apexcharts-active{
        z-index: 1 !important;
    }
    .piechart-container{
        min-height: 356px !important;
        min-width: 712px !important;
        overflow: visible !important;

    }
    .rs-modal-content{
        width: 712px !important;
        height: 356px !important; 
        border-radius: 22px !important;
    } 
    .apexcharts-yaxis{
        min-width: 40px !important;
    }
    .rs-picker-toggle{
        font-size: 13px !important;
        font-weight: 500 !important;
    }

    .rs-icon:hover{
        fill:rgb(207, 26, 26);
        font-weight: 500;
    }