
//
// Form-Upload
//

/* Dropzone */
.dropzone {
  min-height: 230px;
  border: 2px dashed var(--#{$prefix}border-color);
  background: var(--#{$prefix}custom-white);
  border-radius: 6px;

  .dz-message {
    font-size: 24px;
    width: 100%;
  }
}

.dropzone-btn {
  height: 40px;
  width: 100%;
  border-radius: 8px;
  margin-top: 10px;
  border: 2px solid #008CBA;
  color: #008CBA;
  font-size: 11px;
  background-color: #DEE5E6; 

  .dz-btn-message {
    font-size: 24px;
    text-align: center;
    padding-top: 2%;
    width: 100%;
    &:focus{
      outline: none;
    }
  }
}

.dropzone-btn:hover {
    transition-duration: 0.4s;
    background-color: #d6ebee; 
    color: #d7e3e7;
    border: 2px solid #dee5e6;

  .dz-btn-message {
    font-size: 24px;
    text-align: center;
    padding-top: 2%;
    width: 100%;
    &:focus{
      outline: none;
    }
  }
  
}
.dropzone-btn:hover h6{
  color: #008CBA;
}