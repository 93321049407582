.card-view {
  border-radius: 15px;
  border: 1px solid rgb(219, 219, 219);
  box-shadow: none;
  background-color: #fcfcfc;
}

.card-view1 {
  box-shadow: none;
  background-color: transparent;
  background-color: #fcfcfc;
  padding: 0;
}

.card-view2 {
  box-shadow: rgb(0, 0, 0);
  border-radius: 15px;
  border: 0.5px solid rgb(245, 245, 245);
}

.card-view4 {
  background-color: #fcfcfc;
}

.map {
  border-radius: 15px;
  height: 300px;
  width: 100%;
  overflow: hidden;
}

.status {
  box-sizing: border-box;
  font-size: 16px;
  vertical-align: top;
  color: #000000;
  font-weight: 500;
  text-align: center;
  border-radius: 35px;
  margin: 0;
  padding: 20px;
}

.custom-box {
  background-color: #8dd98e;
  height: 300px;
}

.custom-reload {
  font-size: 120px;
  height: 150px;
  color: #ffff;
  justify-content: center;
}

.data-text {
  color: #495057;
  padding-left: 20px;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
}

.project-description-text {
  color: #495057;
  text-align: justify;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.proponent-data-text {
  color: #495057;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
}

.crediting-data-text-small {
  padding-left: 20px;
  color: #495057;
  text-align: justify;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.crediting-data-retired {
  padding-left: 40px;
  color: #495057;
  text-align: justify;
  font-family: Poppins;
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.data-title {
  color: #9c9c9c;
  text-align: justify;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.data-title-sub {
  color: #959595;
  text-align: justify;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  background-color: #ffffff;
}

.data-table {
  background-color: #ffffff;
  width:"100%";

  th {
    color: #495057;
    text-align: justify;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    background-color: #fcfcfc;
  }

  td {
    color: #9c9c9c !important;
    text-align: justify;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    border-bottom: none;
    font-weight: bold;
    background-color: #fcfcfc;
  }
}

/* InfoWindowStyles.css */

.info-window-container {
  max-width: 160px;
  width:100%;
  // min-height: 55px;
  height: 100%;
  border-radius: 10px;
  background: #fff;

  /* SHADOW */
  box-shadow: 0px 3px 19px -5px rgba(0, 0, 0, 0.24);

  position: relative;

  z-index: 1;
}

.info-window-container > div {
  margin: 0;
  padding: 0;
}

.info-window-title {
  color: #495057;
  text-align: justify;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1.4;
}

.info-window-location {
  color: #a1a1a1;
  text-align: justify;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 1.4;
}

.info-window-coordinates {
  color: #a1a1a1;
  text-align: justify;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 1.4;
  margin-top: -5px;
}

.fixed-height-card {
  height: 175px;
}

.card-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon-color {
  color: #959595;
}

.icon-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding-top: 15px;
}

.icon {
  border-radius: 6px;
  padding: 10px;
  margin: 0;
}

.icon-container {
  transition: transform 0.3s ease;
}

.icon-container:hover {
  transform: scale(1.3);
}

// test

/* apexchart.css */
.apexcharts-svg .apexcharts-bar-area rect {
  border-radius: 5px 5px 0 0; /* Adjust the values to control the roundness */
}

.apexcharts-canvas {
  overflow: visible !important;
}

.pagination-item {
  font-weight: bold;
  font-size: 11px;
  color: #cccccc;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  a {
    color: #ccc;
  }
}

.pagination-active-item {
  a {
    color: #495057;
  }
}

.pagination-button {
  border-radius: 3px;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #ccc;
  a {
    color: #ccc;
  }
}

.data-table .black-text {
  color: rgb(73,80,87) !important;
  font-weight:400;
}

.data-table .ash-text {
  color: rgb(156,156,156) !important;
}

