.chart {
  margin: 20px 0 20px 1px; /* Correcting the bottom margin to 20px */
}

@media (max-width: 768px) {
  .chart {
    margin-bottom: 800px; /* Adjusted bottom margin for medium screens */
  }
}

@media (min-width: 769px) {
  .chart {
    margin-bottom: 500px; /* Adjusted bottom margin for large screens */
  }
}


.legend-square {
  border-radius: 10px !important;
}

.download-btn{
  margin-top: '-40px';
  padding-right: '20px';
  position: 'absolute';
  top: '0';
  right: '0';
  color: #000000; 
  background: none;
  border: none;
  cursor: pointer;
}

download-btn:hover{
  color: rgb(22, 117, 227); 
}

